import { inject, Injectable } from '@angular/core';
import { SpreeService } from '../../services/spree-client/storefront/spree.service';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  pipe,
  ReplaySubject,
  skip,
  take,
  tap,
  UnaryFunction,
} from 'rxjs';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import {
  AccountAddressAttr,
  IOrder,
  JsonApiDocument,
  OrderAttr,
  RelationType,
} from '../../services/spree-client/storefront';
import { Variant } from '../product/product-page/product.types';
import { StateService } from '../../services/state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Address, Cart, LineItem, Promotion } from './cart.types';
import { DataLayerService } from '../../services/data-layer.service';
import { CheckoutStepsService } from '../../components/checkout/checkout-steps.service';
import {
  deserializeLineItem,
  filterIncludedLineItems,
} from 'src/app/serializers/line-items.serializer';
import { CookieKey } from '../../types/cookie-key';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

const CART_INCLUDE = {
  include:
    'line_items.variant.product.taxons,line_items.variant.product.images,billing_address,shipping_address,line_items.public_metadata,promotions',
};

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private state = inject(StateService);
  private client = inject(SpreeService);
  private cookies = inject(SsrCookieService);
  private readonly checkoutSteps = inject(CheckoutStepsService);
  private readonly dataLayer = inject(DataLayerService);
  private readonly auth = inject(AuthenticationService);

  private readonly cart = new BehaviorSubject<Cart>({
    _id: 0,
    email: '',
    number: '',
    state: '',
    total: '',
    totalAmount: 0,
    itemTotal: '',
    itemTotalAmount: 0,
    shipTotal: '',
    shipTotalAmount: 0,
    taxTotalAmount: 0,
    adjustmentTotal: '',
    lineItems: [],
    itemCount: 0,
    completedAt: null,
    currency: '',
    token: '',
    address: {
      shipping: {} as Address,
      billing: {} as Address,
    },
    summaryItems: [],
    payment: { url: '', token: '' },
    promotions: [],
    publicMetadata: {},
  } as Cart);
  private readonly error = new ReplaySubject<{
    [actionOrId: string | number]: string;
  } | null>(1);
  private readonly isLoading = new BehaviorSubject(true);
  private readonly openMiniCart = new BehaviorSubject(false);

  cart$ = this.cart.asObservable();
  error$ = this.error.asObservable();
  isLoading$ = this.isLoading.asObservable();
  itemCount$ = this.cart$.pipe(map((cart) => cart.itemCount));
  openMiniCart$ = this.openMiniCart.asObservable();

  constructor() {
    this.auth.isLoggedIn$
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((isLoggedIn) => {
        console.log({ isLoggedIn });

        if (isLoggedIn) {
          this.cart$
            .pipe(
              filter((cart) => !!cart.currency),
              take(1),
              switchMap((cart) =>
                this.associateCart(this.auth.token, cart.token, cart.currency),
              ),
            )
            .subscribe();
        } else {
          this.getCart();
        }
      });
  }

  getPublicMetadata(): Record<string, unknown> {
    return this.cart.getValue().publicMetadata;
  }

  getCart(): void {
    this.isLoading.next(true);

    const orderToken = this.cookies.get(CookieKey.OrderToken);

    const method = orderToken
      ? this.getExistingCart(orderToken).pipe(catchError(() => this.getNewCart()))
      : this.getNewCart();

    method.subscribe(this.emitCart);
  }

  addItem(variant: Variant | LineItem, from: 'product page' | 'cart page'): void {
    this.isLoading.next(true);

    const variant_id =
      typeof variant.variantId === 'string'
        ? variant.variantId
        : variant.variantId.toString();
    const orderToken = this.cookies.get(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    this.client.cart
      .addItem({
        variant_id,
        quantity: 1,
        order_token: orderToken,
        currency,
        ...CART_INCLUDE,
      })
      .pipe(
        this.mapCart(),
        tap((cart) => {
          this.dataLayer.onAddToCart(variant, from);
          this.dataLayer.onViewCart(cart);
        }),
      )
      .subscribe({
        next: (cart) => {
          this.openMiniCart.next(true);

          return this.emitCart(cart);
        },
        error: (error) => this.emitError('addItem', error),
      });
  }

  changeQuantity(item: LineItem, quantity: number, mode: 'add' | 'remove'): void {
    this.isLoading.next(true);

    const orderToken = this.cookies.get(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    this.client.cart
      .setQuantity({
        line_item_id: item.id.toString(),
        quantity,
        order_token: orderToken,
        currency,
        ...CART_INCLUDE,
      })
      .pipe(
        this.mapCart(),
        tap(() =>
          mode === 'add'
            ? this.dataLayer.onAddToCart(item, 'cart page')
            : this.dataLayer.onRemoveFromCart(item, 'cart page', 1),
        ),
      )
      .subscribe({
        next: (cart) => this.emitCart(cart),
        error: (error) => this.emitError(item.id, error),
      });
  }

  removeItem(item: LineItem): void {
    if (this.isLoading.getValue()) {
      return;
    }

    this.isLoading.next(true);

    const orderToken = this.cookies.get(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    this.client.cart
      .removeItem({
        id: item.id.toString(),
        order_token: orderToken,
        currency,
        ...CART_INCLUDE,
      })
      .pipe(
        tap(() => {
          this.dataLayer.onRemoveFromCart(item, 'cart page', item.quantity);
        }),
        this.mapCart(),
      )
      .subscribe({
        next: (cart) => {
          this.isLoading.next(false);

          if (cart.itemCount === 0) {
            if (cart.promotions.length > 0) {
              this.removeAllCoupons();

              return;
            }

            this.emitCart(cart);
            this.checkoutSteps.initSteps();
          } else {
            this.emitCart(cart);
          }
        },
        error: () => {
          this.getCart();
        },
      });
  }

  // todo rework as static pipe
  emitIOrderAsCart(order: Observable<IOrder>): Observable<Cart> {
    return order.pipe(
      this.mapCart(),
      tap((cart) => this.emitCart(cart)),
    );
  }

  clearError(): void {
    this.error.next({});
  }

  closeMiniCart(): void {
    this.openMiniCart.next(false);
  }

  associateCart(
    bearerToken: string,
    cartToken: string,
    currency: string,
  ): Observable<Cart> {
    return this.emitIOrderAsCart(
      this.client.cart.associateGuestCart({
        guest_order_token: cartToken,
        bearer_token: bearerToken,
        currency,
        ...CART_INCLUDE,
      }),
    );
  }

  applyCoupon(code: string): void {
    this.isLoading.next(true);
    const orderToken = this.cookies.get(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    this.client.cart
      .applyCouponCode({
        order_token: orderToken,
        currency,
        coupon_code: code,
        ...CART_INCLUDE,
      })
      .pipe(this.mapCart())
      .subscribe({
        next: (cart) => this.emitCart(cart),
        error: (error) => this.emitError('coupon Code', error),
      });
  }

  removeCoupon(code: string): void {
    this.isLoading.next(true);
    const orderToken = this.cookies.get(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    this.client.cart
      .removeCouponCode({
        order_token: orderToken,
        currency,
        coupon_code: code,
        ...CART_INCLUDE,
      })
      .pipe(this.mapCart())
      .subscribe({
        next: (cart) => this.emitCart(cart),
      });
  }

  private removeAllCoupons(): void {
    const orderToken = this.cookies.get(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    this.client.cart
      .removeAllCoupons({
        order_token: orderToken,
        currency,
        ...CART_INCLUDE,
      })
      .pipe(this.mapCart())
      .subscribe((cart) => this.emitCart(cart));
  }

  private getNewCart(): Observable<Cart> {
    this.cookies.delete(CookieKey.OrderToken);
    const currency = this.state.getActiveCurrency();

    return this.client.cart.create({ currency }).pipe(
      // save order token as a cookie
      tap((cart) => {
        this.cookies.set(CookieKey.OrderToken, cart.data.attributes.token, { path: '/' });
        console.log('newCartToken', cart.data.attributes.token);
      }),
      switchMap((cart) => {
        if (this.auth.isLoggedIn()) {
          return this.associateCart(
            this.auth.token,
            cart.data.attributes.token,
            cart.data.attributes.currency,
          );
        }

        return of(cart).pipe(this.mapCart());
      }),
    );
  }

  private getExistingCart(token: string): Observable<Cart> {
    const currency = this.state.getActiveCurrency();

    return this.client.cart
      .show({
        order_token: token,
        currency,
        ...CART_INCLUDE,
      })
      .pipe(this.mapCart());
  }

  private emitCart = (cart: Cart): void => {
    this.cart.next(cart);
    this.error.next({});
    this.isLoading.next(false);

    if (cart.itemCount <= 0) {
      this.openMiniCart.next(false);
    }
  };

  private emitError = (actionOrId: string | number, error: HttpErrorResponse): void => {
    this.error.next({ [actionOrId]: error.error.error });
    this.isLoading.next(false);
  };

  private mapCart: () => UnaryFunction<Observable<IOrder>, Observable<Cart>> = () =>
    pipe(map((order: IOrder) => deserializeCart(order.data, order.included ?? [])));
}

export const deserializeCart = (
  apiCart: OrderAttr,
  included: JsonApiDocument[],
): Cart => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  return {
    _id: parseInt(apiCart.id, 10),
    email: apiCart.attributes.email,
    number: apiCart.attributes.number,
    state: apiCart.attributes.state,
    total: apiCart.attributes.display_total,
    currency: apiCart.attributes.currency,
    totalAmount: parseFloat(apiCart.attributes.total),
    itemTotalAmount: parseFloat(apiCart.attributes.item_total),
    itemTotal: apiCart.attributes.display_item_total,
    shipTotal: apiCart.attributes.display_ship_total,
    shipTotalAmount: parseFloat(apiCart.attributes.ship_total),
    taxTotalAmount: parseFloat(apiCart.attributes.tax_total),
    adjustmentTotal: apiCart.attributes.display_adjustment_total,
    lineItems: filterIncludedLineItems(included, apiCart).map((item) =>
      deserializeLineItem(item, included),
    ),
    itemCount: apiCart.attributes.item_count,
    address: findAddress(apiCart, included),
    completedAt: apiCart.attributes.completed_at,
    token: apiCart.attributes.token,
    summaryItems: returnSummaryItems(included, apiCart),
    payment: mapPaymentData(included),
    promotions: deserializePromotions(included),
    publicMetadata: apiCart.attributes.public_metadata,
  };
};

const mapPaymentData = (included: JsonApiDocument[]): { url: string; token: string } => {
  const foundData = included.find((d) => d.type === 'payment')?.attributes[
    'public_metadata'
  ] as Record<string, string>;

  return {
    url: foundData?.['payment_url'] ?? '',
    token: foundData?.['token'] ?? '',
  };
};

const returnSummaryItems = (
  included: JsonApiDocument[],
  apiCart: OrderAttr,
): { name: string; value: number }[] => {
  const summaryArray = [
    {
      name: 'Products total',
      value: parseFloat(apiCart.attributes.item_total),
    },
  ];

  const adjustmentTotal = parseFloat(apiCart.attributes.adjustment_total);

  if (adjustmentTotal < 0) {
    summaryArray.push({
      name: 'Discount',
      value: adjustmentTotal,
    });
  }

  const shippingFinalPrice = included.find((e) => e.type === 'shipping_rate')?.attributes[
    'final_price'
  ] as string;

  if (shippingFinalPrice) {
    summaryArray.push({
      name: 'Shipping cost',
      value: parseFloat(shippingFinalPrice),
    });
  }

  return summaryArray;
};

const deserializePromotions = (included: JsonApiDocument[]): Promotion[] => {
  const promotions = included.filter((item) => item.type === 'promotion');

  if (!promotions) {
    return [];
  }

  return promotions.map((item) => ({
    name: item.attributes['name'] as string,
    description: item.attributes['description'] as string,
    amount: item.attributes['amount'] as string,
    display_amount: item.attributes['display_amount'] as string,
    code: item.attributes['code'] as string,
  }));
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const findAttachment = (attachments: JsonApiDocument[], id: string, type: string) => {
  return attachments.find((e) => e.id === id && e.type === type);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const findAddress = (data: OrderAttr, included: JsonApiDocument[]) => {
  const shippingAddressId =
    (data.relationships['shipping_address'].data as RelationType)?.id || undefined;
  const billingAddressId =
    (data.relationships['billing_address'].data as RelationType)?.id || undefined;

  const shippingAddress = findAttachment(
    included,
    shippingAddressId ? shippingAddressId : '',
    'address',
  );
  const billingAddress = findAttachment(
    included,
    billingAddressId ? billingAddressId : '',
    'address',
  );

  return {
    shipping: shippingAddress
      ? deserializeAddress(shippingAddress as AccountAddressAttr)
      : ({} as Address),
    billing: billingAddress
      ? deserializeAddress(billingAddress as AccountAddressAttr)
      : ({} as Address),
  };
};

export const deserializeAddress = (apiAddress: AccountAddressAttr): Address => ({
  _id: apiAddress.id,
  firstName: apiAddress.attributes.firstname,
  lastName: apiAddress.attributes.lastname,
  company: apiAddress.attributes.company!,
  addressLine1: apiAddress.attributes.address1,
  addressLine2: apiAddress.attributes.address2!,
  postalCode: apiAddress.attributes.zipcode,
  city: apiAddress.attributes.city,
  state: apiAddress.attributes.state_name,
  country: apiAddress.attributes.country_iso,
  phone: apiAddress.attributes.phone!,
});
